@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Marcellus&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #0D1B2A; 
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: #0D1B2A;
  padding-top: 1px;
}


.App-header {
  background-color: #1E2A38;
  /*background: linear-gradient(to right, #051A30, #95D8E8); */
  min-height: 10vh;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-image {
  max-width: 28%;
  height:auto;
  padding-left: 40%;
}

.main-layout {
  display: flex;
  margin-top: 20px; /* Add margin to align with the chat-container */
  transition: all 0.3s ease; /* Smooth transition */
}

.quick-actions-panel {
  width: 200px;
  background-color: #1E2A38;
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, width 0.3s ease;
  position: relative;
}

.quick-actions-panel.closed {
  transform: translateX(-90%);
  width: 0;
  padding: 0;
  margin-right: 0;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 20%;
  margin-bottom: 10px;
  font-size: 18px; 
  font-weight: bold; 
  color: white; 
  transition: opacity 0.3s ease;
}
.quick-actions-panel.closed .panel-header {
  opacity: 0; 
}

.info-icon {
  cursor: pointer;
  font-size: 14px;
  margin-right: 50px;
}

.toggle-button {
  background-color: #005B99;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 0px; /* Right position when open */
  transition: background-color 0.3s ease, right 0.3s ease;
  z-index: 1;
}

.quick-actions-panel.closed .toggle-button {
  right: -27px; /* Right position when closed */
}

.quick-actions {
  margin-top: 50px;
}

.quick-action-button {
  background-color: #005B99;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.quick-action-button:hover {
  background-color: #007ACC;
}
.header-content {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.header-text {
  font-family: 'Marcellus', serif;
  font-size: 35px;
  margin-top: 5px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 20px;
  background-color: #1E2A38;
  border-radius: 10px;
  overflow-y: hidden;
  flex-grow: 1;
  width: calc(75% - 220px); /* Adjusted width */
  margin: 0 auto; /* Center the container */
  transition: all 0.3s ease; /* Smooth transition */
  margin-right: 10px;
  margin-left: 10px;
}

.chat-container.expanded {
  width: 75%;
  margin-left: 10px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  margin: 10px 0;
  color: #D9D9D9;
}

.user-message {
  background: linear-gradient(to right, #005B99, #007ACC); 
  color: #FFFFFF;
  text-align: left;
  margin-left: auto;
  padding: 10px;
  border-radius: 25px;
  max-width: 50%;
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 13px;
}

.ai-message {
  background-color: #1E2A38;
  color: #D9D9D9;
  text-align: left;
  margin-right: auto;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 13px;
}

.assistant-logo {
  width: 35px; /* Adjust the width as needed */
  height: 35px; /* Adjust the height as needed */
  border-radius: 50%;
  overflow: hidden;
}

.ai-message pre {
  background-color: #333333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  position: relative;
  max-width: 65%;
}

.code-block {
  position: relative;
  margin-bottom: 10px;
  border-radius: 5px;
}

.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444444;
  color: white;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.copy-button {
  background-color: #61dafb;
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.copy-button:hover {
  background-color: #21a1f1;
}

.code-language {
  font-size: 0.8em;
  font-weight: bold;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #0D1B2A;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  width: 100%;
  border-radius: 50px;
  box-sizing: border-box;
}

.input-container textarea {
  flex-grow: 1;
  padding-right: 15px;
  padding-left: 20px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  resize: none;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 50px;
  height: 2em;
  max-height: 6em; /* 4 lines of text */
  overflow-y: auto; /* Scroll when the content exceeds max-height */
}

.input-container textarea::placeholder {
  color: white;
  height: 2em;
  max-height: 6em;
}

.input-container button {
  flex-shrink: 0;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  margin-left: 5px;
  height: 31px;
  width: 31px;
}

.input-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.square-button {
  flex-shrink: 0;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  margin-left: 5px;
  height: 31px;
  width: 31px;
}

.square-icon {
  color: #444444;
  justify-content: center;
}

.arrow-up-button {
  flex-shrink: 0;
  background-color: white;
  color: black;
  border: none;
  border-radius: 40%;
  cursor: pointer;
  outline: none;
  margin-left: 5px;
  height: 31px;
  width: 31px;
}

.arrow-up-icon {
  color: #444444;
  justify-content: center;
}

a {
  color: #61dafb;
  text-decoration: none;
}

a:visited {
  color: #076f87; /* Slightly lighter than #61dafb */
}
